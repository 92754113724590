<template>
<div class="loader text-center mt-4">
      <b-skeleton-table v-if="isTable" :rows="r" :columns="c"></b-skeleton-table>
      <div v-else>
            <b-spinner style="width: 5rem; height: 5rem;" variant="primary"></b-spinner>
            <p class="mt-4">{{text}}</p>
      </div>
</div>
</template>

<script>
export default {
      name: 'Loader',
      props: ["loadingtext", "rows", "cols"],       
      computed: {
            text() {
                  if (this.loadingtext)
                        return this.loadingtext;
                  else return "Hämtar data...";
            },
            r(){
                  return parseInt(this.rows);
            },
            c(){
                  return parseInt(this.cols);
            },
            isTable() {
                  if ((this.rows && this.rows > 0) ||
                        this.cols && this.cols > 0) {
                        return true;
                  }
                  return false;
            }
      },
      methods: {}
};
</script>
